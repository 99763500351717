import { NFSLogoText } from '@nfs/ui-kit';
import PropTypes from 'prop-types';

export default function Logo({ defaultLogo }) {
    let retval;

    if (
        typeof window !== 'undefined' &&
        window.location.hostname.match(/allstate/i)
    ) {
        retval = (
            <img
                src="/assets/allstate/interior/logo.svg"
                alt="logo"
                width="150"
            />
        );
    } else {
        retval = defaultLogo ?? <NFSLogoText />;
    }

    return retval;
}

Logo.propTypes = {
    /**
     * The logo to use when there is no white-label logo.  Defaults to <NFSLogoText />
     */
    defaultLogo: PropTypes.node
};
