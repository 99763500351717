import { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import HelpTile from './help-tile';
import HelpContent from './help-content';
import HelpDialog from './help-dialog';

const Help = ({ tiles, setTiles, onBack, data, loadData, ...props }) => {
    const [activeTile, setActiveTile] = useState(null);
    const [defaultView, setDefaultView] = useState(true);

    const helpTiles = useMemo(
        () =>
            tiles.map(({ key, icon, title, visited }) => (
                <HelpTile
                    key={key}
                    icon={icon}
                    title={title}
                    visited={visited}
                    onClick={() => {
                        setActiveTile(key);
                        setDefaultView(false);
                    }}
                />
            )),
        [tiles]
    );

    const helpContent = useMemo(() => {
        if (activeTile) {
            const { content } = tiles.find(({ key }) => activeTile === key);
            return <HelpContent {...content} />;
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTile]);

    useEffect(() => {
        if (props.open && !data) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, data]);

    return (
        <HelpDialog
            {...props}
            title={defaultView ? props.title : null}
            onBack={() => {
                setActiveTile(null);
                setDefaultView(true);
                const idx = tiles.findIndex(({ key }) => activeTile === key);
                if (!tiles[idx].visited) {
                    const newTiles = [...tiles];
                    newTiles[idx].visited = true;
                    setTiles(newTiles);
                }
                onBack(activeTile);
            }}
            defaultView={defaultView}
        >
            {activeTile ? helpContent : helpTiles}
        </HelpDialog>
    );
};

Help.propTypes = {
    ...HelpDialog.propTypes,
    tiles: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            ...HelpTile.propTypes,
            content: PropTypes.shape(HelpContent.propTypes)
        })
    ),
    setTiles: PropTypes.func,
    loadData: PropTypes.func,
    data: PropTypes.object
};

Help.defaultProps = {
    ...HelpDialog.defaultProps,
    tiles: [],
    setTiles: () => {},
    onBack: () => {},
    loadData: () => {},
    data: null
};

export default memo(Help);
