import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

const Livechat = props => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
        <g
            fill={props.disabled ? '#c0c0c0' : '#FFF'}
            fillRule="evenodd"
            opacity=".88"
        >
            <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z" />
        </g>
    </SvgIcon>
);

Livechat.propTypes = {
    disabled: PropTypes.bool
};

Livechat.defaultProps = {
    disabled: false
};

export default Livechat;
