import PropTypes from 'prop-types';
import { memo, forwardRef, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton
} from '@material-ui/core';
import {
    AlternateEmail,
    Call,
    Chat,
    Close,
    Launch,
    Help as HelpIcon,
    ArrowBack
} from '@material-ui/icons';
import classes from './styles.module.scss';
import usePageLoad from '../../../hooks/usePageLoadQuery';

const TENANTS_WHO_NOT_SHOWN_LINK = ['21660', '21660'];

const HelpDialog = forwardRef(
    (
        {
            contact,
            title,
            floodInsuranceResources,
            floodProLink,
            handleChat,
            children,
            onBack,
            defaultView,
            shouldEnableChat,
            ...props
        },
        ref
    ) => {
        const attrs = { ref };

        const { data: pageLoadData, loading: pageLoadLoading } = usePageLoad();

        const [showLink, setShowLink] = useState(false);

        useEffect(() => {
            const tenantCode = pageLoadData?.userTenants?.[0]?.tenantCode;

            setShowLink(
                pageLoadLoading
                    ? false
                    : !TENANTS_WHO_NOT_SHOWN_LINK.some(
                          item => item === tenantCode
                      )
            );
        }, [pageLoadData, pageLoadLoading]);

        return (
            <Dialog
                aria-labelledby="NeptuneHelpDialogTitle-title"
                fullWidth
                maxWidth="md"
                {...props}
                {...attrs}
            >
                <DialogTitle className={classes.header} disableTypography>
                    {defaultView ? (
                        <Box px={3} className={classes.title} component="span">
                            {title}
                        </Box>
                    ) : (
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={onBack}
                            startIcon={<ArrowBack />}
                        >
                            Back
                        </Button>
                    )}
                    <IconButton
                        aria-label="close"
                        onClick={props.onClose}
                        color="primary"
                        className={classes.close}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box mx={3}>
                        {defaultView ? (
                            <Box pt={4}>
                                <Grid container spacing={3}>
                                    {children}
                                </Grid>
                            </Box>
                        ) : (
                            children
                        )}
                    </Box>
                    {defaultView && (
                        <Box fontSize={16} p={4}>
                            Looking for more? Visit our{' '}
                            <Button
                                data-testid="floodins-link"
                                target="_blank"
                                href={floodInsuranceResources}
                                endIcon={<Launch fontSize="small" />}
                                variant="text"
                                color="primary"
                            >
                                Flood Insurance Resources
                            </Button>{' '}
                            for additional support.
                        </Box>
                    )}
                </DialogContent>
                <DialogActions className={classes.static} disableSpacing>
                    <Box className={classes.contactPanel} p={4}>
                        <Box className={classes.contactHeader}>
                            Still have questions or need additional help?
                            Contact us.
                        </Box>
                        <Grid container justify="space-evenly">
                            {shouldEnableChat && (
                                <Grid container item md={4} direction="column">
                                    <Grid item>
                                        <Button
                                            className={classes.link}
                                            variant="text"
                                            color="primary"
                                            startIcon={
                                                <Chat
                                                    className={
                                                        classes.contactIcon
                                                    }
                                                    fontSize="small"
                                                />
                                            }
                                            onClick={handleChat}
                                        >
                                            Live chat with our flood specialists
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            ml={6}
                                            className={classes.infoText}
                                        >
                                            Click the chat icon in the upper
                                            right to start.{' '}
                                            {contact.liveChatHours}.
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container item md={3} direction="column">
                                <Grid item>
                                    <Button
                                        className={classes.link}
                                        href={`tel:${contact.phone}`}
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <Call
                                                className={classes.contactIcon}
                                                fontSize="small"
                                            />
                                        }
                                    >
                                        {contact.phone}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Box ml={6} className={classes.infoText}>
                                        {contact.callHours}.
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} direction="column">
                                <Grid item>
                                    <Button
                                        className={classes.link}
                                        href={`mailto:${contact.email}`}
                                        variant="text"
                                        color="primary"
                                        startIcon={
                                            <AlternateEmail
                                                className={classes.contactIcon}
                                                fontSize="small"
                                            />
                                        }
                                    >
                                        {contact.email}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Box ml={6} className={classes.infoText}>
                                        {contact.emailHours}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box ml={4}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            {showLink && (
                                <Grid item md={4}>
                                    <Button
                                        data-testid="fp-link"
                                        target="_blank"
                                        href={floodProLink}
                                        endIcon={<Launch fontSize="small" />}
                                        variant="text"
                                        color="primary"
                                        className={classes.link}
                                    >
                                        Go to FloodPro
                                    </Button>
                                </Grid>
                            )}

                            <Grid
                                container
                                item
                                md
                                justify="flex-end"
                                alignItems="center"
                            >
                                <HelpIcon
                                    className={classes.contactIcon}
                                    fontSize="small"
                                />
                                <Box pl={1} className={classes.infoText}>
                                    Access this page again with the help icon in
                                    the upper right corner
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
);
HelpDialog.displayName = 'NeptuneHelpDialog';
HelpDialog.propTypes = {
    ...Dialog.propTypes,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    handleChat: PropTypes.func,
    defaultView: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string,
    contact: PropTypes.shape({
        phone: PropTypes.string,
        callHours: PropTypes.string,
        email: PropTypes.string
    }),
    floodInsuranceResources: PropTypes.string,
    floodProLink: PropTypes.string,
    shouldEnableChat: PropTypes.bool
};

HelpDialog.defaultProps = {
    ...Dialog.defaultProps,
    onClose: () => {},
    onBack: () => {},
    handleChat: () => {},
    defaultView: true,
    open: false,
    title: '{{title}}',
    contact: {
        phone: '{{phone}}',
        callHours: '{{callHours}}',
        email: '{{email}}',
        emailHours: '{{emailHours}}',
        liveChatHours: '{{liveChatHours}}'
    },
    floodProLink: '#',
    floodInsuranceResources: '#',
    shouldEnableChat: false
};

export default memo(HelpDialog);
