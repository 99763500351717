import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const HelpContent = ({ header, caption, sections }) => {
    return (
        <Grid container item direction="column" spacing={2}>
            <Grid className={classes.header} item>
                {header}
            </Grid>
            <Grid className={classes.caption} item>
                {caption}
            </Grid>
            <Grid container item spacing={2}>
                {sections.map(({ text, img, jsx }, i) => (
                    <Grid
                        // eslint-disable-next-line react/no-array-index-key
                        key={`help-section-${i}`}
                        container
                        item
                        spacing={4}
                        justify="center"
                        alignItems="flex-start"
                        alignContent="space-between"
                    >
                        <Grid container item md className={classes.ol}>
                            {text && (
                                <>
                                    <Grid item xs={1}>
                                        <Box pr={2}>{i + 1}.</Box>
                                    </Grid>

                                    <Grid item xs className={classes.content}>
                                        {text}
                                    </Grid>
                                </>
                            )}
                            {jsx}
                        </Grid>
                        <Grid item md>
                            {img && (
                                <Box p={1}>
                                    <img
                                        className={classes.screen}
                                        src={img}
                                        alt={`img-${i + 1}`}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

HelpContent.propTypes = {
    header: PropTypes.string,
    caption: PropTypes.string,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            img: PropTypes.string,
            jsx: PropTypes.node
        })
    )
};

HelpContent.defaultProps = {
    header: '',
    caption: '',
    sections: []
};

export default HelpContent;
