import { gql, useQuery, useLazyQuery } from '@apollo/client';

export const GET_LIST_OF_RESTRICTED_TENANT_CONVERSION_CONFIGS = gql`
    query ListOfRestrictedTenantConversionConfigs(
        $loggedInTenantCodes: [String!]
        $applicationProducerTenantCode: String
        $expirationDate: GoogleType_DateInput
        $activeAddressState: String
        $hasPreviousPayments: Boolean
    ) {
        listOfRestrictedTenantConversionConfigs(
            loggedInTenantCodes: $loggedInTenantCodes
            applicationProducerTenantCode: $applicationProducerTenantCode
            expirationDate: $expirationDate
            activeAddressState: $activeAddressState
            hasPreviousPayments: $hasPreviousPayments
        ) {
            disableNewBusinessQuoting
            disableNewBusinessQuotingMessage {
                header
                body
                contact
            }
            disableNewBusinessPayments
            disablePolicyDetailsSummary
            disablePolicySearch
            disableLiveChat
            disableDocumentsActions
            disableDashboardRenewalsTab
            disableEndorsementQuoting
            disableEndorsementPayments
            disableRenewalProcessing
            disableRenewalPayments
            disableCancellationSubmissionAndProcessing
            disableRolloversQuoting
            disableRolloversPayments
            disableMultiProducer
            disableUserSystemAccess
            disableNoteTaking
        }
    }
`;

export default function FindListOfRestrictedTenantConversionConfigs(options) {
    const loggedInTenantCodesUnfiltered =
        options?.variables?.loggedInTenantCodes;
    const loggedInTenantCodes = Array.isArray(loggedInTenantCodesUnfiltered)
        ? loggedInTenantCodesUnfiltered.filter(Boolean)
        : [];

    return useQuery(GET_LIST_OF_RESTRICTED_TENANT_CONVERSION_CONFIGS, {
        skip: loggedInTenantCodes.length === 0,
        fetchPolicy: 'network-only',
        ...options,
        variables: {
            ...options.variables,
            loggedInTenantCodes
        }
    });
}

export const useLazyFindConversionConfigs = () => {
    const [method, data] = useLazyQuery(
        GET_LIST_OF_RESTRICTED_TENANT_CONVERSION_CONFIGS
    );

    return [
        options => {
            const loggedInTenantCodesUnfiltered =
                options?.variables?.loggedInTenantCodes;
            const loggedInTenantCodes = Array.isArray(
                loggedInTenantCodesUnfiltered
            )
                ? loggedInTenantCodesUnfiltered.filter(Boolean)
                : [];

            return method({
                skip: loggedInTenantCodes.length === 0,
                fetchPolicy: 'network-only',
                ...options,
                variables: {
                    ...options.variables,
                    loggedInTenantCodes
                }
            });
        },
        data
    ];
};
