// import { Box } from '@material-ui/core';
import getConfig from 'next/config';
import {
    Block,
    CreateOutlined,
    Search,
    VerifiedUserOutlined
} from '@material-ui/icons';
import TasksIcon from '../icons/tasks';

const { publicRuntimeConfig } = getConfig() ?? {};

const { NEXT_PUBLIC_POLICY_DETAILS_ENDORSE_BUTTON_ENABLED } =
    publicRuntimeConfig ?? {};

const HELP_TILES = [
    {
        key: 'policies',
        title: 'Finding your Policies',
        icon: Search,
        content: {
            header: 'Finding your Quotes and Policies',
            caption:
                'Use the search bar at the top of any page to find a policy.',
            sections: [
                {
                    text:
                        'Search by name, address, quote number, application number or policy number. It works just like Google!',
                    img: '/assets/help/find1.png'
                },
                {
                    text: `As you type, results will start to appear.  If you see the item you are looking for, click it to open.   If you don't, press "return" or "enter" on your keyboard to see the full results.`,
                    img: '/assets/help/find2.png'
                },
                {
                    text:
                        'On the full results page, use the filters near the top to filter by City, State, Status and more.',
                    img: '/assets/help/find3.png'
                },
                {
                    text:
                        'Click the Quote / Policy Number to open the details page for that policy.   From there, you can take other actions like endorsing, or downloading a declaration page.'
                }
            ]
        }
    },
    {
        key: 'quotes',
        icon: VerifiedUserOutlined,
        title: 'Quotes & Applications',
        content: {
            header: 'Starting a Quote or Application',
            caption: `It's easy to start a quote, fill out an application and pay for a policy.`,
            sections: [
                {
                    text: `Click or tap the "+ New Quote" button in the upper right of the screen:`,
                    img: '/assets/help/quote1.png'
                },
                {
                    text: `Begin filling in the form.  When you've entered enough information to rate, the premium due will be automatically returned. This amount will be displayed on the right side of the screen in the "Quote" section.`,
                    img: '/assets/help/quote2.png'
                },
                {
                    text: `As you make additional changes, the calculated premium due will update.   Any time an amount is showing, you can download a PDF of the currently-displayed quote.`,
                    img: '/assets/help/quote3.png'
                },
                {
                    text: `You may also change the coverage amounts in the "Quote" section to re-calculate the premium due for all coverage options.  

                    The system will automatically save any quotes or applications that you have not signed into a policy.   SEARCH FOR QUOTES to reopen a previously-saved quote or application to make updates or sign into a policy.   
                    `,
                    img: '/assets/help/quote4.png'
                }
            ]
        }
    },
    {
        key: 'tasks',
        title: 'Tasks & Recent Work',
        icon: TasksIcon,
        content: {
            header: 'Tasks and Recent Work',
            caption: `Tasks and Recent Work help you understand any work you or your client need to do in order to complete a policy, endorsement, cancellation and more.`,
            sections: [
                {
                    text: `When you log in, you should see the "Action Required" tab is active.  All your tasks appear here.  If you have to tasks, the page will appear empty`,
                    img: '/assets/help/tasks1.png'
                },
                {
                    text: `Tasks in the "Action Required" tab are organized by type, age, and due date so that you know what type of action is required to complete a new policy, endorsement or cancellation.`,
                    img: '/assets/help/tasks2.png'
                },
                {
                    text: `Click on a task to see the details and the action you must take in order to complete the task.   Typical actions include (but are not limited to) things like following up with your clients for a specific document, and collecting payments.`,
                    img: '/assets/help/tasks3.png'
                },
                {
                    text: `Clicking on the "Recent Work" tab will show the 50 most recent quotes and policies that you've worked on.`,
                    img: '/assets/help/tasks4.png'
                }
            ]
        }
    },
    {
        key: 'endorse',
        title: 'Endorsing a Policy',
        icon: CreateOutlined,
        content: {
            header: 'Endorsing a Policy',
            caption: `It's easy to endorse a policy!`,
            sections: [
                {
                    text: `Search for the policy you want to endorse.  See our help article on how to SEARCH FOR QUOTES / POLICIES if you're not sure how to do this.`,
                    img: '/assets/help/endorse1.png'
                },
                {
                    text: `Click on the policy you want to endorse in the search results page.  This will open up the policy detail page.`,
                    img: '/assets/help/endorse2.png'
                },
                {
                    text: `On the Policy Detail page, click the "Endorse" icon in the upper right of the screen to open the Endorsement page.`,
                    img: '/assets/help/endorse3.png'
                },
                {
                    text: `The endorsement page will load the current policy data.  Review the form and change the fields needed to complete the endorsement.   

                    You can navigate to the section that you need to change using the quick links on the right side of the page.  
                    `,
                    img: '/assets/help/endorse4.png'
                },
                {
                    text: `Make the changes needed. Coverage can be changed in the right side panel of the page. If premium is due, the quoted endorsement premium will display on the right side panel.`,
                    img: '/assets/help/endorse5.png'
                },
                {
                    text: `Once you've made your changes, click "Review Changes" to continue`,
                    img: '/assets/help/endorse6.png'
                },
                {
                    text: `Review your changes. If you made a mistake or forgot a change, you can click "go back" to edit.`,
                    img: '/assets/help/endorse7.png'
                },
                {
                    text: `Click "Sign & Submit" to finish your endorsement.`,
                    img: '/assets/help/endorse8.png'
                },
                {
                    text: `If additional premium is due, enter the Credit Card or E-check information to pay online via pay.gov.`
                },
                {
                    text: `The endorsement will be processed and the insured will receive a revised declaration page.`
                }
            ]
        }
    },
    {
        key: 'cancel',
        title: 'Cancelling a Policy',
        icon: Block,
        content: {
            header: 'Cancelling a Policy',
            caption: `It's easy to cancel a policy!`,
            sections: [
                {
                    text: `Search for the policy you want to cancel.  See our help article on how to SEARCH FOR QUOTES / POLICIES if you're not sure how to do this.`,
                    img: '/assets/help/cancels1.png'
                },
                {
                    text: `Click on the policy you want to cancel in the search results page. This will open up the policy detail page. `,
                    img: '/assets/help/cancels2.png'
                },
                {
                    text: `On the Policy Detail page, click the "Cancel" icon in the upper right of the screen to open the Cancel page.`,
                    img: '/assets/help/cancels3.png'
                },
                {
                    text: `The cancel page will ask you for the cancel reason, effective date and refund recipient information.  

                    If required (depending on the cancel reason you chose), the system will ask for insured signature, documentation to prove the cancellation is valid, and agent signature.`,
                    img: '/assets/help/cancels4.png'
                }
            ]
        }
    }
    // {
    //     key: 'claims',
    //     title: 'Claims & Reports',
    //     icon: ClaimsIcon,
    //     content: {
    //         header: 'File a Claim',
    //         caption: `You can file a claim on behalf of your policyholders or they can do it themselves by following the steps below.`,
    //         sections: [
    //             {
    //                 text: `Visit myflood.com and click "FILE YOUR CLAIM HERE"`,
    //                 img: '/assets/help/claims1.png'
    //             },
    //             {
    //                 text: `Enter the 14-digit policy number (the last four numbers are the term year).  Then enter the property's zip code, the policy holder's name and email address and click "Login".`,
    //                 img: '/assets/help/claims2.png'
    //             },
    //             {
    //                 text: `Click either "FILE YOUR CLAIM HERE" or "Report a Loss".  This will take you to the First Notice of Loss (FNOL) form.`,
    //                 img: '/assets/help/claims3.png'
    //             },
    //             {
    //                 text: `Fill out the FNOL form with as complete detail as you can provide and click "Submit Loss".`,
    //                 img: '/assets/help/claims3.png'
    //             },
    //             {
    //                 jsx: (
    //                     <>
    //                         <Box fontWeight="bold">NOTES:</Box>
    //                         <ul>
    //                             <li>
    //                                 {`For faster processing, verify the
    //                                 mortgage & lender information is current
    //                                 and correct.`}
    //                             </li>
    //                             <li>
    //                                 {`Be sure to ask your insured for a
    //                                 temporary mailing address if they are
    //                                 displaced from their home.`}
    //                             </li>
    //                         </ul>
    //                     </>
    //                 )
    //             }
    //         ]
    //     }
    // }
    // ,{
    //     key: 'renewal',
    //     title: 'Renewing a Policy',
    //     icon: Autorenew
    // },
    // {
    //     key: 'rollover',
    //     title: 'Rolling over a Policy'
    // }
];
export const getHelpTiles = wyo => {
    let helpTiles = HELP_TILES;

    // Defaults to true if there is no value
    const isEndorsementLive =
        NEXT_PUBLIC_POLICY_DETAILS_ENDORSE_BUTTON_ENABLED?.toString().toLowerCase() !==
        'false';

    // Filters out endorse tile based on FF above
    if (!isEndorsementLive) {
        helpTiles = helpTiles.filter(({ key }) => key !== 'endorse');
    }

    switch (wyo?.toLowerCase()) {
        case 'allstate':
            return helpTiles.filter(({ key }) => key !== 'claims');
        default:
            return helpTiles;
    }
};
