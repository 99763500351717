import { gql, useQuery } from '@apollo/client';

export const PAGE_LOAD_QUERIES = gql`
    query PolicyBuilderPageLoad {
        userTenants {
            agency
            agentCode
            companyName
            insOrgId
            isProducer
            tenantCode
        }

        contactTypes {
            label
            value
        }

        addressTypes {
            label
            value
        }

        occupancyTypes {
            label
            value
        }

        engineeredFloodOpeningTypes {
            label
            value
        }

        primaryResidenceTypes {
            label
            value
        }

        foundationTypes {
            label
            value
        }

        wallMaterials {
            label
            value
        }

        policyTypes {
            label
            value
        }

        devices {
            label
            value
        }

        states: usRegions {
            text: name
            value: iso
        }

        countries {
            text: name
            value: iso
        }

        floodZones

        userInfo: introspect {
            id
            emailAddress
            userName
            continuingEducation {
                requireAttestation
                hasEnhanced
            }
            userProfileId
            userRole
        }
    }
`;

export default function usePageLoad() {
    return useQuery(PAGE_LOAD_QUERIES);
}
