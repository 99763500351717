import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CheckBoxOutlineBlank, CheckCircle } from '@material-ui/icons';
import classes from './styles.module.scss';

const HelpTile = ({ icon, title, visited, onClick }) => {
    const Icon = icon;
    return (
        <Grid item md={2} className={classes.container}>
            <Button
                variant="outlined"
                color="secondary"
                classes={classes}
                onClick={onClick}
            >
                {visited && <CheckCircle className={classes.visited} />}
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={11}>
                        <Icon fontSize="large" />
                    </Grid>
                    <Grid item xs={11} className={classes.title}>
                        {title}
                    </Grid>
                </Grid>
            </Button>
        </Grid>
    );
};

HelpTile.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    visited: PropTypes.bool,
    onClick: PropTypes.func
};

HelpTile.defaultProps = {
    icon: CheckBoxOutlineBlank,
    title: '',
    visited: false,
    onClick: () => {}
};

export default HelpTile;
