import PropTypes from 'prop-types';
import Script from 'next/script';
import React from 'react';
import { withApollo } from '../../lib/apollo';

const AssurantChat = props => {
    const { pathname } = props;

    // the assurant liveperson webtag only runs when the tagcount is undefined.
    // In order to force the tag to re-run, we set the tagcount back to undefined whenever the page changes
    if (typeof window?.lpTag?._tagCount !== 'undefined') {
        window.lpTag._tagCount = undefined;
    }

    const theScript = React.useMemo(() => {
        return (
            <Script
                src={`/scripts/assurant-liveperson.js?${new Date().getTime()}`}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        // STATUS ENUM
        // 0-Complete, 1-In Progress, 2-Approved, 3-cancelled, 4-Not Approved,
        // 5-Reviewed, 6-Missing Details, 7-Closed, 8-Removed, 9-Assigned,
        // 10-Waiting for Customer Response, 11-Waiting for Response, 12-Pending, 13-Resolved
        <div
            style={{ maxWidth: '24px', maxHeight: '24px', overflow: 'hidden' }}
        >
            {theScript}
            <div id="lp-button" />
        </div>
    );
};

AssurantChat.propTypes = {
    pathname: PropTypes.string.isRequired
};

export { AssurantChat };

export default withApollo({ ssr: true })(AssurantChat);
