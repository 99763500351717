import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() ?? {};
const {
    NEXT_PUBLIC_ALLSTATE_TENANTS,
    NEXT_PUBLIC_ASSURANT_TENANTS,
    NEXT_PUBLIC_LIVECHAT_ALLSTATE_ACCOUNT_ID,
    NEXT_PUBLIC_LIVECHAT_ACCOUNT_ID
} = publicRuntimeConfig ?? {};

const GetLiveChatID = userTenants => {
    const isAllstateTenant = userTenants?.some(({ tenantCode }) =>
        NEXT_PUBLIC_ALLSTATE_TENANTS?.split(',')?.includes(tenantCode)
    );

    const isAssurantTenant = userTenants?.some(({ tenantCode }) =>
        NEXT_PUBLIC_ASSURANT_TENANTS?.split(',')?.includes(tenantCode)
    );

    if (isAssurantTenant) {
        return {
            id: '',
            url: '',
            provider: 'livePerson'
        };
    }

    const livechatID = isAllstateTenant
        ? NEXT_PUBLIC_LIVECHAT_ALLSTATE_ACCOUNT_ID
        : NEXT_PUBLIC_LIVECHAT_ACCOUNT_ID;

    if (livechatID && process.browser) {
        // eslint-disable-next-line dot-notation
        window['__lc'] = window['__lc'] || {};
        // eslint-disable-next-line dot-notation
        window['__lc'].license = livechatID;
    }

    return {
        id: livechatID,
        url: `https://direct.lc.chat/${livechatID}/`,
        provider: 'liveChat'
    };
};

export default GetLiveChatID;
